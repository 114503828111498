import Layout from "../components/layout";
import React, {useState} from "react";
import gql from "graphql-tag";
import {Query} from "react-apollo/index";
import {datoClient} from '../apollo/client';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMapMarkerAlt, faSpinner, faTimes} from "@fortawesome/free-solid-svg-icons";
import '../css/instagram.min.css'
import Like from "../components/Like";
import SEO from "../components/seo";
import {graphql} from "gatsby";


export const ALL_LOOKBOOKS = gql`
    query AllLookbooks {
        allLookbooks(first:30,orderBy:[createdAt_DESC]) {
            id
            email
            city{
                name
            }
            givenName
            familyName
            description
            filter
            image{
                url
            }
        }
    }
`

export default ({data: {datoCmsHomepage: h}}) => {
  const [id, setId] = useState(null)

  const LookBookDetail = ({d}) => <div className="fixed inset-0 z-50 overflow-auto bg-smoke-light flex">
    <div className="relative bg-white w-full max-w-xl m-auto p-0 flex">
      <div className="hidden md:block w-1/2 object-contain" style={{lineHeight: 0}}>
        <img className={`filter-${d.filter}`} src={`${d.image.url}?fit=crop&w=400&h=500`} alt={d.description}/>
      </div>
      <div className="w-1/2 flex flex-col flex-1">
        <button onClick={() => setId(null)} className='self-end m-2'>
          <FontAwesomeIcon className='text-gray-800' icon={faTimes} size="2x"/>
        </button>
        <div className="p-2 lg:p-16">
          <div className='font-sans uppercase my-2'>{d.givenName}&nbsp;{d.familyName}</div>
          <div className='font-sans my-2 font-light'>{d.description}</div>
          <div className='font-sans my-2 font-light'><FontAwesomeIcon className='text-green-700'
                                                                      icon={faMapMarkerAlt}
                                                                      size="1x"/> {d.city && d.city.name}</div>
        </div>
      </div>
    </div>
  </div>

  return (
    <Layout>
      <SEO title="LookBook" seo={h.seoMetaTags}/>
      <h1 className="font-title font-bold text-4xl uppercase mt-8 mb-2 text-center tracking-wider">Lookbook</h1>
      <Query
        query={ALL_LOOKBOOKS}
        client={datoClient}
      >
        {({loading, error, data}) => {
          if (loading) {
            return <FontAwesomeIcon className='text-gray-800 mx-4' icon={faSpinner} spin={true} size="2x"/>
          }
          if (error) {
            return <div className="font-sans text-red-700">Something went wrong, please try again later</div>
          }
          const d = id && data.allLookbooks.find(l => l.id === id)
          return (
            <div className="flex flex-wrap my-8 justify-center w-full">
              {data.allLookbooks.map(d => (
                <div key={d.id} className='m-1 mb-4'>
                  <button className='object-contain' onClick={() => setId(d.id)} style={{lineHeight: 0}}>
                    <img className={`filter-${d.filter}`} src={`${d.image.url}?fit=crop&w=400&h=500`}
                         alt={d.description}/>
                  </button>
                  <div className='flex font-sans font-light justify-between items-center'>
                    <FontAwesomeIcon className='text-green-700' icon={faMapMarkerAlt} size="1x"/>
                    <span className='ml-2 mr-4 text-start'>{d.city && d.city.name}</span>
                    <span className='ml-2 flex-1 text-start italic'>{d.givenName} {d.familyName}</span>
                    <Like model='lookbook' id={d.id}/>
                  </div>
                </div>
              ))}
              {d && <LookBookDetail d={d}/>}
            </div>);
        }}
      </Query>
      {id}
    </Layout>);
}

export const query = graphql`
    query LookBookPage {
        datoCmsHomepage {
            seoMetaTags {
                ...GatsbyDatoCmsSeoMetaTags
            }
        }
    }
`
